import React from 'react'
import PropTypes from 'prop-types'
import navigator from 'navigator'

import pic01 from '../images/me.jpg'
import pic02 from '../images/code.png'
import cvEng from '../cv/Yanis_CV_ENG.pdf'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
if (typeof navigator !== `undefined`) {
  const navigator = require("navigator")
}

library.add(fab);

const styleBoxSkills = {
  display: "flex",
  justifyContent: "space-between"
};

const styleBoxDefine = {
  display: "flex",
  justifyContent: "center",
}

const styleBoxBorder = {
  padding: "2px 13px",
  border: "1.9px solid #fff",
  borderRadius: "7px",
}

const listType = {
  listStyleType: "none"
};

const getOs = () => {
  const os = ['Windows', 'Linux', 'Mac'];
  return os.find(v=>navigator.appVersion.indexOf(v) >= 0);
}

const getLanguage = () => {
  return navigator.language || navigator.userLanguage;
}

const appleOrNot = () => (getOs() === "Mac" ? <a href="https://mega.nz/file/v44gkDzC#0zqYHRNCrQXSqLY8UiVoOHcC7rBqYwEEpKKyQNf4bOQ" target="_blank">download here the Keynote presentation !</a> : <a href="https://mega.nz/file/O9gwCZAK#F4re03__kTUoe8TxsZ7qlwRZ39Ot2W3MrwgMDvBudcM" target="_blank">download here the PowerPoint file !</a>);

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          <span className="image main"><img src={pic01} alt="Myself looking at the user, smiling. Wearing a suit" /></span>
          <p>I'm a graduated student, passionated by many things, but mostly by <ins>Web Developement</ins> you can check my awesome (👀) work on this website and my <a href="http://github.com/MrStrikes" target="_blank" rel="noreferrer">GitHub</a> webpage.</p>
          <p>I'm also very passionated about football, and automobile sports, like Formula 1 or the World Rallye Championship. Furthermore, I love astronomy, the fact that it's infinite <i>(nb. yeah yeah, I know, it's not infinite)</i>. I'm also a gamer <i>(wanted to be pro, my mom stepped in and my projects were cleared off before it even started)</i>, I love and will always love video games. I mainly play co-op and racing video games, like <ins>the PAYDAY franchise</ins>, <ins>Left 4 Dead</ins>, <ins>FIFA</ins>, and <ins>the Football Manager franchise</ins> <i>(my fav one)</i></p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Work</h2>
          <div className="project">
            <h2><FontAwesomeIcon icon={['fab', 'node-js']} />&nbsp;<FontAwesomeIcon icon={['fab', 'vuejs']} />&nbsp;<FontAwesomeIcon icon={['fab', 'react']} />&nbsp;<FontAwesomeIcon icon={['fab', 'symfony']} />&nbsp;Deeday: </h2>
            <p>This was our end of studies project.</p>
            <li style={listType}>
              <ul><strong>Languages: </strong><ins>NodeJS (TypeScript) / React Native / VueJS / Symfony 4 / PostgreSQL</ins></ul>
              <ul><strong>Goal of the project: </strong>From nothing, create a whole project with an ecological concern.</ul>
              <ul><strong>What it taught to me ?: </strong>How to manage and develop and two years long project. The main difficulty was to operate all together to find a suitable project with a great interest. After that, trying to find great timing for everyone to get working together, then discover new techs, apply new knowledge, learnt day after day, managing this with the studies and interships was, certainly the hardest constraint. Once everything was putted, all went smooth, and the project goes way deeper than what we expected at the start</ul>
              <ul><strong>Link: </strong><FontAwesomeIcon icon={['fal', 'exclamation-triangle']} /> To get a better viewpoint of the complete project, you can {appleOrNot()}</ul>
            </li>
          </div>
          <div className="project">
            <h2><FontAwesomeIcon icon={['fab', 'node-js']} />&nbsp;<FontAwesomeIcon icon={['fab', 'angular']} />&nbsp;Wisebatt Back Office: </h2>
            <p>This project is my first project I made in my internship.</p>
            <li style={listType}>
              <ul><strong>Language: </strong><ins>NodeJS (MEAN Stack)</ins></ul>
              <ul><strong>Goal of the project: </strong>Create from scratch a whole back office.</ul>
              <ul><strong>What it taught to me ?: </strong>A lot of things. Firstly, the JavaScript stack. I never used NodeJS before for making a website back-end with ExpressJS. Secondly, MongoDB. I always used relationnal databases like MySQL, this was a completely new experience, then I learned AngularJS. I started it on school, but only the basics. After this, I get into it a little bit deeper in my internship.</ul>
              <ul><strong>Link: </strong><FontAwesomeIcon icon={['fal', 'exclamation-triangle']} /> As it's a back office, all of this is stricly confidential.</ul>
            </li>
          </div>
          <div className="project">
            <h2><FontAwesomeIcon icon={['fab', 'php']} />&nbsp;Twittr: </h2>
            <p>This project was a replica of website Twitter.</p>
            <li style={listType}>
              <ul><strong>Language: </strong><ins>PHP</ins></ul>
              <ul><strong>Goal of the project: </strong>Make the same website as Twitter. Create the same functions, and the same way that Twitter works.</ul>
              <ul><strong>What it taught to me ?: </strong>This project reinforced my skills in OOP and MVC programmation and a better way to work in a pair team.</ul>
              <ul><strong>Link: </strong><FontAwesomeIcon icon={['fab', 'github-square']} />&nbsp;<a href="http://github.com/MrStrikes/Twittr" target="_blank" rel="noreferrer">Twittr project</a></ul>
            </li>
          </div>
          <div className="project">
            <h2><FontAwesomeIcon icon={['fab', 'php']} />&nbsp;Filer 1 & 2: </h2>
            <p>This is the first big project I had to develop. This is a Google Drive type of website.</p>
            <li style={listType}>
              <ul><strong>Language: </strong><ins>PHP</ins></ul>
              <ul><strong>Goal of the project: </strong>Make a website like Google Drive using native PHP. This project taught us the management of a login system, a file system upload, a decent security.</ul>
              <ul><strong>What it taught to me ?: </strong>This project taught me how to manage a login system, a file system upload, a decent security. This was the first version of the project. The second version taught us OOP and use of our own framework.</ul>
              <ul><strong>Link: </strong><FontAwesomeIcon icon={['fab', 'github-square']} />&nbsp;<a href="http://github.com/MrStrikes/filer" target="_blank" rel="noreferrer">Filer 1</a> and <a href="http://github.com/MrStrikes/Filer2" target="_blank" rel="noreferrer">Filer 2</a></ul>
            </li>
          </div>
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Skills</h2>
          <span className="image main"><img src={pic02} alt="Some piece of code. React one. This is the code of the footer of this website." /></span>
          <h3>Here are my current skills:</h3>
          <div className="box-definition" style={styleBoxDefine}>
            <p style={styleBoxBorder}><FontAwesomeIcon icon={['fab', 'laptop-code']}/>&nbsp;Development Skills</p>
          </div>
          <div className="box-skills" style={styleBoxSkills}>
            <p><FontAwesomeIcon icon={['fab', 'html5']} />&nbsp;HTML5</p>
            <p><FontAwesomeIcon icon={['fab', 'css3']} />&nbsp;CSS3</p>
          </div>
          <div className="box-skills" style={styleBoxSkills}>
            <p><FontAwesomeIcon icon={['fab', 'php']} />&nbsp;PHP</p>
            <p><FontAwesomeIcon icon={['fab', 'symfony']} />&nbsp;Symfony</p>
          </div>
          <div className="box-skills" style={styleBoxSkills}>
            <p><FontAwesomeIcon icon={['fab', 'node-js']} />&nbsp;NodeJS</p>
            <p><FontAwesomeIcon icon={['fab', 'js-square']} />&nbsp;JS</p>
          </div>
          {close}
          <div className="box-skills" style={styleBoxSkills}>
            <p><FontAwesomeIcon icon={['fab', 'vuejs']} />&nbsp;VueJS</p>
            <p><FontAwesomeIcon icon={['fab', 'react']} />&nbsp;React JS</p>
            <p>&nbsp;Typescript</p>
            <p><FontAwesomeIcon icon={['fab', 'angular']} />&nbsp;AngularJS</p>
          </div>
          <div className="box-skills" style={styleBoxSkills}>
            <p><FontAwesomeIcon icon={['fab', 'github-square']} />&nbsp;GitHub</p>
            <p><FontAwesomeIcon icon={['fab', 'terminal']} />&nbsp;CLI</p>
          </div>
          <div className="box-definition" style={styleBoxDefine}>
            <p style={styleBoxBorder}><FontAwesomeIcon icon={['fab', 'mobile']}/>&nbsp;Mobile Skills</p>
          </div>
          <div className="box-skills" style={styleBoxSkills}>
            <p><FontAwesomeIcon icon={['fab', 'swift']} />&nbsp;Swift</p>
            <p><FontAwesomeIcon icon={['fab', 'java']} />&nbsp;Java / Kotlin</p>
            <p><FontAwesomeIcon icon={['fab', 'react']} />&nbsp;React Native</p>
          </div>
          <div className="box-definition" style={styleBoxDefine}>
            <p style={styleBoxBorder}><FontAwesomeIcon icon={['fab', 'satellite-dish']}/>&nbsp;Soft Skills</p>
          </div>
          <div className="box-skills" style={styleBoxSkills}>
            <p><FontAwesomeIcon icon={['fab', 'users']} />&nbsp;Teamwork</p>
            <p><FontAwesomeIcon icon={['fab', 'wine-glass']} />&nbsp;French - C2</p>
            <p><FontAwesomeIcon icon={['fab', 'mug-tea']} />&nbsp;English - C1</p>
          </div>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <div className="mail-zone">
            <h3 style={{margin: "5px"}}><FontAwesomeIcon icon={['fal', 'at']} /> <a href="mailto:yanis.bendahmane@supinternet.fr" rel="noreferrer">Email me</a>:</h3>
            <p style={{marginLeft: "5%"}}>me@yanisbendahmane.com</p>
          </div>
          <div className="phone-zone">
            <h3 style={{margin: "5px"}}><FontAwesomeIcon icon={['fal', 'phone-square']} /> <a href="tel:+33767722883" rel="noreferrer">Phone Number</a>:</h3>
            <p style={{marginLeft: "5%"}}>(+33) 7 67 72 28 83</p>
          </div>
          <div className="github-zone">
            <h3 style={{margin: "5px"}}><FontAwesomeIcon icon={['fab', 'github-square']} /> <a href="http://github.com/MrStrikes" target="_blank" rel="noreferrer">GitHub account</a>:</h3>
            <p style={{marginLeft: "5%"}}>MrStrikes</p>
          </div>
          <div className="linkedin-zone">
            <h3 style={{margin: "5px"}}><FontAwesomeIcon icon={['fab', 'linkedin']} /> <a href="https://www.linkedin.com/in/yanis-bendahmane-d%C3%A9veloppeur-fullstack-js-vue-react-node/" target="_blank" rel="noreferrer">LinkedIn</a>:</h3>
            <p style={{marginLeft: "5%"}}>Yanis Bendahmane</p>
          </div>
          <div className="cv-zone">
            <h3 style={{margin: "5px"}}><FontAwesomeIcon icon={['fal', 'caret-square-right']} /> <a href={cvEng} target="_blank" rel="noreferrer">Link to my CV</a></h3>
          </div>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main